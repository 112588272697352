<template>
  <section class="celebs-management">
    <v-card class="celebs-container">
      <v-row>
        <v-col
          cols="12"
          md="3"
          class="text-center">
          <p class="text-h4 font-text-bold">
            CELEBS
          </p>
        </v-col>
        <v-col
          cols="10"
          offset="1"
          offset-md="3"
          md="3">
          <v-text-field
            v-model="tempSearch"
            placeholder="ชื่อ, IG หรือ Facebook"
            outlined
            dense
            hide-details
            append-icon="mdi-magnify"
            @click:append="searchHandler()" />
        </v-col>
        <v-col
          md="3"
          cols="10"
          offset="1"
          offset-md="0"
          class="pr-4">
          <v-btn
            block
            color="success"
            @click="createModalToggle(true)">
            Add new celeb
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <celeb-table
          v-model="query"
          :items="celebs"
          :loading="loading"
          @click:remove="removeCelebById($event)"
          @click:edit="editCeleb($event)" />
      </v-card-text>
    </v-card>

    <create-celeb-modal
      v-model="createModalActive"
      :edit-data="editData"
      @create:celeb="fetchCelebs()" />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import CelebProvider from '@/resources/CelebProvider'

import CelebTable from '../components/CelebTable.vue'
import CreateCelebModal from '../components/CreateCelebModal.vue'

const CelebService = new CelebProvider()

export default {
  components: {
    CelebTable,
    CreateCelebModal
  },
  data () {
    return {
      searchDelay: null,
      tempSearch: null,
      createModalActive: false,
      celebs: [],
      loading: false,
      query: {
        search: null,
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      },
      editData: null
    }
  },
  watch: {
    query: {
      handler () {
        this.fetchCelebs()
      },
      deep: true
    }
  },
  mounted () {
    this.fetchCelebs()
  },
  methods: {
    ...mapActions({
      setModal: 'Components/setModal',
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    searchHandler () {
      // clearTimeout(this.searchDelay)

      // this.searchDelay = setTimeout(() => {
      //   this.query.search = this.tempSearch
      // }, 1000)

      this.query.search = this.tempSearch
    },
    async removeCelebById (id) {
      try {
        this.loading = true
        this.setModal({
          value: true,
          title: 'Remove celeb',
          message: 'Do you want to remove celeb?',
          confirmText: 'Confirm',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: async () => {
            const { data } = await CelebService.deleteCeleb(id)

            if (data) {
              this.setSnackbar({
                value: true,
                message: 'Celeb has been deleted',
                type: 'success'
              })

              this.fetchCelebs()
            }
          }
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[error on delete celeb]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    editCeleb (item) {
      this.editData = item
      this.createModalToggle(true)
    },
    createModalToggle (value) {
      this.createModalActive = value
    },
    async fetchCelebs () {
      try {
        this.loading = true
        const { data } = await CelebService.getCelebs(this.query)
        this.celebs = data.results
        this.query.total = data.total
        this.query.pages = data.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[error on create celeb]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.celebs-container {
  min-height: calc(100vh - 67px);
}

</style>
